<!-- 招生系统基础配置 -- 员工账号管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back()).catch(()=>{})" :cancelRemind="false" confirmText="保存" cancelText="取消
      ">
		
    <el-form :model="form" :rules="rules" ref="form" label-width="200rem" style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="姓名" prop="name">
        <el-input placeholder="请输入" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="form.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="手机号（账号）" prop="username">
        <el-input placeholder="请输入" maxlength="11" v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="password">
        <el-input placeholder="请输入" maxlength="20" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="id_card">
        <el-input placeholder="请输入" v-model="form.id_card"></el-input>
      </el-form-item>
      <el-form-item label="入职时间" prop="entry_time">
        <el-date-picker v-model="form.entry_time" type="date" value-format="yyyy-MM-dd" placeholder="请选择">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="所属校区" prop="school">
        <el-select v-model="form.school" placeholder="请选择">
          <el-option v-for="item in accountConfig.school" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可招生校区" prop="admissible">
        <el-checkbox-group v-model="form.admissible">
          <el-checkbox v-for="item in accountConfig.admissible" :label="item.id">{{ item.school_name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="岗位名称" prop="roles">
        <el-select v-model="form.roles" placeholder="请选择">
          <el-option v-for="item in accountConfig.role" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否为部门负责人" prop="department_head">
        <el-radio-group v-model="form.department_head">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="家长是否可见" prop="isParentsSee">
        <el-radio-group v-model="form.is_parents_see">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
<!--      <el-form-item v-if="isCustomerShow" label="查看客户方式" prop="customer">-->
<!--        <el-radio-group v-model="form.customer">-->
<!--          <el-radio :label="0">按招生老师查</el-radio>-->
<!--          <el-radio :label="1">按报名校区查</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item label="查看客户方式" prop="customer">
        <el-radio-group v-model="form.customer">
          <el-radio :label="0">按招生老师查</el-radio>
          <el-radio :label="1">按报名校区查</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开户行" prop="bank">
        <el-select filterable="" remote="" :remote-method="bankHandle" v-model="form.bank" :loading="bankLoading" placeholder="请选择">
          <el-option v-for="item in bankOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户省份" prop="province">
        <el-select v-model="form.province" placeholder="请选择" @change="getCity">
          <el-option v-for="item in provinceOptions" :label="item.address_name" :value="item.address_code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户城市" prop="city">
        <el-select v-model="form.city" placeholder="请选择" @change="getArea">
          <el-option v-for="item in cityOptions" :label="item.address_name" :value="item.address_code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户区/县" prop="area">
        <el-select v-model="form.area" placeholder="请选择">
          <el-option v-for="item in areaOptions" :label="item.address_name" :value="item.address_code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户网点" prop="bank_branch">
        <el-input placeholder="请输入" maxlength="50" v-model="form.bank_branch"></el-input>
      </el-form-item>
      <el-form-item label="银行账号" prop="bank_card">
        <el-input placeholder="请输入" v-model="form.bank_card"></el-input>
      </el-form-item>
      <el-form-item label="银行行号（非必填）" prop="bank_code">
        <el-input placeholder="请输入" v-model="form.bank_code"></el-input>
      </el-form-item>
    </el-form>
    
  
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    const validatePass = (rule, value, callback) => {
      let reg15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;  //15位身份证正则
      let reg18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (reg15.test(value) || reg18.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的身份证号'));
      }
    };
    return {
      backType: 0,  //1为保存后返回，此时需要刷新list; 0为取消后返回，此时不需要刷新
      accountConfig: {},
      bankOptions: [],
      bankLoading: false,
      isCustomerShow: true,
      form: {
        name: '',
        gender: '',
        username: '',
        password: '',
        id_card: '',
        entry_time: '',
        school: '',
        admissible: [],
        roles: '',
        department_head: '',
        is_parents_see: 1,
        customer: '',
        bank: '',
        province: '',
        city: '',
        area: '',
        bank_branch: '',
        bank_card: '',
        bank_code: '',
      },
      rules: {
        name: [{required: true, message: "请输入姓名"}, {
          pattern: /^[\u4e00-\u9fa5\w]{1,30}$/,
          message: "请输入30字以内的汉字，数字和字母"
        }],
        gender: [{required: true, message: "请选择性别"}],
        username: [{required: true, message: "请输入手机号（账号）"}, {
          pattern: /^1[0-9]{10}$/,
          message: "请输入正确的手机号"
        }],
        password: [{required: true, message: "请输入登录密码"}, {
          pattern: /^[A-Za-z0-9]{0,20}$/,
          message: "请输入20个字符内，含数字，字母，不可包含特殊字符，区分大小写"
        }],
        id_card: [{required: true, message: "请输入身份证号"}, {validator: validatePass}],
        entry_time: [{required: true, message: "请选择入职时间"}],
        school: [{required: true, message: "请选择所属校区"}],
        admissible: [{required: true, message: "请选择可招生校区"}],
        roles: [{required: true, message: "请选择岗位名称"}],
        department_head: [{required: true, message: "请选择是否为部门负责人"}],
        is_parents_see: [{required: true, message: "请选择家长是否可见"}],
        customer: [{required: true, message: "请选择是否可查看非本校区客户"}],
        bank: [{required: true, message: "请选择开户行"}],
        province: [{required: true, message: "请选择开户省份"}],
        city: [{required: true, message: "请选择开户城市"}],
        area: [{required: true, message: "请选择开户区/县"}],
        bank_branch: [{required: true, message: "请填写开户网点"}],
        bank_card: [{required: true, message: "请输入银行账号"}, {
          pattern: /^([1-9]{1})(\d{16}|\d{15}|\d{18})$/,
          message: "请输入正确的银行账号"
        }],
        bank_code: [{pattern: /^[0-9]{0,20}$/, message: "请输入长度20以内的数字"}],
      },
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      schoolList: [],
      allSchool: false,
      scopeList: {}
    }
  },
  watch:{
    form:{
      deep: true,
      handler(val){
        this.isCustomerShow = val.department_head == 1;
      }
    }
  },
  created() {
    this.getAccountConfig()
    this.getProvince()
  },
  methods: {
    //获取账户配置
    getAccountConfig() {
      this.$_register('/api/recruit/account/get-param').then(res => {
        this.accountConfig = res.data.data
      })
    },

    getProvince(){
      this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
        this.provinceOptions = res.data.data
      })
    },

    getCity(e){
      this.cityOptions = []
      this.form.city = ''
      this.areaOptions = []
      this.form.area = ''
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.cityOptions = res.data.data
      })
    },

    getArea(e){
      this.areaOptions = []
      this.form.area = ''
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.areaOptions = res.data.data
      })
    },

    //开户行输入值改变时触发
    bankHandle(e){
      if(e != ''){
        this.bankLoading = true
        this.$_register('/api/recruit/account/bank?keyword=' + e).then(res => {
          this.bankOptions = res.data.data
        }).finally(() => {
          this.bankLoading = false
        })
      }
    },

    // 提交数据
    submit() {
      // console.log(this.form)
      this.$refs.form.validate((res, e, p) => {
        if (res) {
          let params = {...this.form}
          // if(!this.isCustomerShow){
          //   params.customer = 0
          // }
          this.$_register.post("/api/recruit/account", params).then(res => {
            this.$message({
              type: 'success',
              message: res.data.msg,
              onClose: () => {
                this.$store.commit("setPage",1);
                this.$router.go(-1);
              }
            });
          })
        }
      })
    },
  },
}
</script>

<style scoped>

.form {
  padding-top: 70rem;
}

::v-deep .el-form .el-input {
  min-width: 250rem;
}
</style>
